import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const PhilosophyPage = () => {
  return (
    <Layout hero={<HeroImage title="Philosophy" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <div className="mb-5">
            <Link className="back-to-link" to='/community/about-us'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to about us
            </Link>
          </div>
          <Row className="d-flex align-items-center mt-5 mb-5">
            <Col md={8}>
              <p>We'll see it through is more than a tag line next to our brand. It is a statement that says a lot about our business and the people who work for Orrcon Steel. It is a part of our culture and it reflects our relationships both internally and externally.</p>
              <p>At Orrcon Steel we are focused on working in partnership with both our internal and external customers. We are committed to adding value to both Orrcon Steel and our customer's business. Customers and staff alike are part of something bigger when they join Orrcon Steel; you are not just simply another number amongst many. </p>
              <p>We want to be known for flexibility, responsiveness, approachability, friendliness, a business that works hard, supplies quality product, and is reliable and consistent. Achieving the above ensures growth, longevity and financial success will naturally follow.</p>
              <p>Our customers also value the “personal touch” we deliver, they are not just another number. Our customers appreciate that we always strive to do the best we can for them. We can never lose sight of this as we grow and evolve - it is a core facet of our business culture.</p>
            </Col>
            <Col md={4}>
              <StaticImage
                src="../../images/honeycomb-pipe.jpeg"
                alt="Tube & Pipe"
                width={340}
                height={340}
                placeholder="blurred"
                layout="fixed" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default PhilosophyPage

export const Head = () => <title>Philosophy | Orrcon Steel</title>
